<template>
  <div>
    <b-dropdown-item
      v-for="item in sendData"
      v-if="item.id > 99 && item.id < 105"
      :key="item.id"
      @click="handleUseStatus(item.id)"
    >
      <feather-icon
        :icon="statusVariant(item.id)"
        class="mr-50"
      />
      <span>{{ item.name }}</span>
    </b-dropdown-item>
  </div>
</template>
<script>

export default {
  components: {},
  props: {
    sendData: {
      type: Array,
      default: _ => {
      },
    },
    idCarCatalog: {
      type: String,
      default: '',
    },
  },
  computed: {
    statusVariant() {
      const iconAction = {
        100: 'BoxIcon',
        101: 'BookIcon',
        102: 'AwardIcon',
        103: 'CommandIcon',
        104: 'CodesandboxIcon',
        105: 'CrosshairIcon',
        106: 'DivideCircleIcon',
      }
      return status => iconAction[status]
    },
  },
  methods: {
    handleUseStatus(id) {
      const routerSend = {
        100: 'car-catalog-overview-gioi-thieu',
        101: 'car-catalog-design',
        102: 'car-catalog-price',
        103: 'car-catalog-operate',
        104: 'car-catalog-intelligent',
        105: 'car-catalog-version',
        106: 'car-catalog-overview',
      }
      this.$router.push({
        name: routerSend[id],
        params: { id: this.idCarCatalog },
      })
    },
  },
}
</script>
