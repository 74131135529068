import { render, staticRenderFns } from "./ModalCreateCarCatalog.vue?vue&type=template&id=64da445a&scoped=true&"
import script from "./ModalCreateCarCatalog.vue?vue&type=script&lang=js&"
export * from "./ModalCreateCarCatalog.vue?vue&type=script&lang=js&"
import style0 from "./ModalCreateCarCatalog.vue?vue&type=style&index=0&id=64da445a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64da445a",
  null
  
)

export default component.exports