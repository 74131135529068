<template>
  <div>
    <b-dropdown-item
      @click="handleViewDetail(props.row.id)"
    >
      <feather-icon
        icon="CodesandboxIcon"
        class="mr-50"
      />
      <span>Xem chi tiết</span>
    </b-dropdown-item>
    <!-- Kích hoạt sử dụng  -->
    <b-dropdown-item
      @click="handleOpenModalQuestion(props.row.id, 3)"
    >
      <feather-icon
        icon="CheckCircleIcon"
        class="mr-50"
      />
      <span>Sử dụng</span>
    </b-dropdown-item>
    <!-- Ngưng sử dụng  -->
    <b-dropdown-item
      @click="handleOpenModalQuestion(props.row.id, 1)"
    >
      <feather-icon
        icon="CrosshairIcon"
        class="mr-50"
      />
      <span>Ngừng sử dụng</span>
    </b-dropdown-item>
  </div>
</template>
<script>

export default {
  components: {},
  props: {
    dataAction: {
      type: Array,
      default: _ => {
      },
    },
    statusId: {
      type: Number,
      default: 0,
    },
    statusName: {
      type: String,
      default: '',
    },
    // statusColorSet: {
    //   type: String,
    //   default: '',
    // },
  },
  mounted() {
  },
  methods: {
    canExecuteAction(action, actions) {
      if (actions === null) return false
      return actions.find(x => x.id === action) !== undefined
    },
    handleUpdate(id) {
      this.$emit('handleUpdate', id)
    },
    handleDelete(id) {
      this.$emit('handleDelete', id)
    },
    handleViewDetail(id) {
      this.$emit('handleViewDetail', id)
    },
    handleUseStatus(id, val) {
      this.$emit('handleUseStatus', id, val)
    },
  },
}
</script>
