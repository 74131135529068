<template>
  <b-modal
    :id="idModalCreateCarCatalog"
    size="md"
    title="Thêm loại xe"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateCarCategory"
    @hidden="resetState"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
        >
          <b-form-group>
            <label>Ảnh đại diện <span class="required">(*)</span></label>
            <b-row>
              <b-col
                xs="12"
                sm="5"
                md="4"
                xl="4"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  size="sm"
                  @click="handler_open_library(1)"
                >
                  <feather-icon
                    icon="CloudIcon"
                    class="mr-50"
                  />
                  Chọn ảnh
                </b-button>
                <library-modal
                  :id-library-model="'id-library-modal'"
                  @handleCloseLibraryModal="handleCloseLibraryModal"
                />
              </b-col>
              <b-col
                xs="12"
                sm="7"
                md="8"
                xl="8"
                class="text-center"
              >
                <img
                  class="img-responsive"
                  style="width: 100%!important;"
                  :src="(avatarLink)"
                  alt=""
                >
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
        >
          <b-form-group>
            <label>Tên loại xe <span class="required">(*)</span></label>
            <b-form-input
              id="InputHelp"
              v-model="queryBody.name"
              placeholder="Nhập tên loại xe ..."
              autocomplete="off"
              autofocus
            />
            <small
              v-if="!$v.queryBody.name.required"
              class="text-danger"
            >
              Không được bỏ trống</small>
          </b-form-group>
          <b-form-group>
            <label>Giá bán đề xuất</label>
            <cleave
              id="txtPrice"
              v-model="queryBody.price"
              class="form-control text-right"
              :raw="false"
              :options="options.number"
              autocomplete="off"
              placeholder="0"
            />
            <br />
            <b-form-checkbox
              id="chkLienHe"
              v-model="queryBody.isContact"
              name="checkbox-1"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Giá bán: Liên hệ
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <label>File brochure</label>
            <b-row>
              <b-col
                xs="12"
                sm="7"
                md="8"
                lg="8"
                xl="9"
              >
                <b-form-input
                  id="txtBrochure"
                  v-model="brochureName"
                  placeholder="File brochure ..."
                  disabled="true"
                />
              </b-col>
              <b-col
                xs="12"
                sm="5"
                md="4"
                lg="4"
                xl="3"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  size="sm"
                  @click="handler_open_library(2)"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import Cleave from 'vue-cleave-component'
import { maxLength, required } from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import LibraryModal from '@/views/ndev-component/library/LibraryModal.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
    Cleave,
    BFormCheckbox,
    LibraryModal,
  },
  directives: {
    Ripple,
  },
  props: {
    idModalCreateCarCatalog: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataCarCatalogDetail: {
      type: Array,
      default: _ => {
      },
    },
    disableProp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      queryBody: {
        name: '',
        price: 0,
        isContact: '',
        avatar: '',
        brochure: '',
      },
      avatarLink: '',
      brochureName: '',
      libraryType: 0,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      status: 'not_accepted',
      isStart: false,
    }
  },
  watch: {
    dataCarCatalogDetail: {
      deep: true,
      handler(val) {
        if (val && this.type === 'edit') {
          this.queryBody = {
            name: this.dataCarCatalogDetail.name,
            price: this.dataCarCatalogDetail.price,
            avatar: this.dataCarCatalogDetail.avatar,
          }
          if (this.dataCarCatalogDetail.isContact === true) {
            this.queryBody.isContact = 'accepted'
          } else {
            this.queryBody.isContact = 'not_accepted'
          }
          if (this.dataCarCatalogDetail.fileViewModal !== null) {
            this.avatarLink = this.callThumbnail(
              this.dataCarCatalogDetail.fileViewModal.fileExtension,
              this.dataCarCatalogDetail.fileViewModal.filePath,
              this.dataCarCatalogDetail.fileViewModal.fileType,
            )
          } else {
            this.avatarLink = ''
          }
          if (this.dataCarCatalogDetail.brochureViewModal !== null) {
            this.brochureName = this.dataCarCatalogDetail.brochureViewModal.name
          } else {
            this.brochureName = ''
          }
        }
        if (val && this.type === 'add') {
          this.queryBody = {
            name: '',
            price: 0,
            isContact: '',
          }
          this.queryBody.avatar = ''
          this.queryBody.brochure = ''
          this.avatarLink = ''
          this.brochureName = ''
        }
      },
    },
  },
  validations: {
    queryBody: {
      name: {
        required,
        maxLength: maxLength(500),
      },
    },
  },
  methods: {
    ...mapActions('users', ['uploadFile']),
    ...mapActions('libraryStore', ['libraryStore_action_ResetBreadCom']),
    handleCreateCarCategory(bvModalEvt) {
      this.isStart = true
      bvModalEvt.preventDefault()
      this.$emit('handleCreateCarCategory', this.queryBody, this.$refs.rule)
    },
    resetState() {
      this.queryBody = {
        name: '',
        price: 0,
        isContact: '',
        avatar: '',
        brochure: '',
      }
      this.avatarLink = ''
      this.brochureName = ''
      this.isStart = false
    },
    openInput() {
      this.handler_open_library()
      // document.getElementById('vue-file-upload-input')
      //     .click()
    },
    callThumbnail(fileExtension, filePath, fileType) {
      return NCoreHelper.GetThumbnail(false, fileExtension, filePath, '64x64', this.$isServer, NCoreHelper.GetIsImageLocal(fileType))
    },
    handler_open_library(type) {
      this.libraryType = type
      this.libraryStore_action_ResetBreadCom()
      this.$bvModal.show('id-library-modal')
    },
    handleCloseLibraryModal(val) {
      // eslint-disable-next-line no-empty
      if (val === null || val === undefined) {

      } else {
        switch (this.libraryType) {
          case 1: {
            this.avatarLink = this.callThumbnail(val.fileExtension, val.filePath, val.fileType)
            this.queryBody.avatar = val.id
            break
          }
          case 2: {
            this.brochureName = val.name
            this.queryBody.brochure = val.id
            break
          }
          default: {
            this.brochureName = val.name
            this.queryBody.brochure = val.id
            break
          }
        }
      }
      this.$bvModal.hide('id-library-modal')
    },
    removeFile(index) {
      //= => Gọi api xóa đi hình ảnh này ======
      this.queryBody.avatar = null
      this.avatarLink = ''
    },
  },
}
</script>

<style lang="scss" scoped>
#appImg {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  margin-right: 20px;

  .btn {
    padding: 4px !important;
  }

  .image-input {
    padding: 3px;
  }

  .image-preview {
    width: 96px;
    height: 96px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 6px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .icon-camera {
    color: white;
    height: 50px;
    width: 50px;
  }

  .dropzone {
    width: 96px;
    height: 96px;
  }

  .dropzone {
    background: #efefef;
    border-radius: 7%;
  }

  .remove-file {
    position: absolute;
    border-radius: 20px;
    margin-top: 28px;
    margin-left: -66px;
    width: 40px;
    height: 40px;
  }

  .remove-file:hover {
    color: white;
    background-color: rgba(130, 134, 139, 0.65);
  }

  .icon-camera-hover {
    width: 28px;
    height: 28px;
    color: rgba(104, 104, 104, 0);
  }

  .icon-camera-hover:hover {
    color: white;
  }
}
</style>
